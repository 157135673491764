import { AiOutlineMail } from "react-icons/ai";
import { FaPhone } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";



export const contactData = [
    {
        label: "Email",
        note: "greenieTec@yahoo.com",
        icon: <AiOutlineMail />
    },
    {
        label: "Phone",
        note: "612-518-2391",
        icon: <FaPhone />
    },
    {
        label: "Address",
        note: "5214 10th Avenue South Minneapolis, MN, 55417",
        icon: <MdLocationPin />
    }

];