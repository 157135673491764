export const navigations = [
   
   
    {
        id: 1,
        name: "Services",
        href: "/services",
        active: false,
        color: "#9E9BCE"

    },
    {
        id: 2,
        name: "Work",
        href: "/work",
        active: false,
        color: "#8ACDB8"
    },
    {
        id: 3,
        name: "Contact",
        href: "/contact",
        active: false,
        color: "#F7D1BA"
    },
    {
        id: 4,
        name: "About",
        href: "/about",
        active: false,
        color: "#C4E8C2"

    }

    
]